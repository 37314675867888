body {
  background: $brand-primary;
  background: linear-gradient(to right, #00A9E7 , #0066B2);
}

#main-wrapper {
  background: $brand-primary;
  margin-top: 10px;
}

footer#footer {
  margin: 0;
  padding: 1em 0;
}

.sidebar {
  min-width: 300px;

  vertical-align: top;
  // background: $brand-primary;
  overflow: hidden;
  @include breakpoint(md){
    width: 30%;
    min-height: 600px;
    max-width: 500px;
  }
  .region-sidebar {
    padding-top: 2em;
    // background: $brand-primary;
    a,span  {
      color: #fff;
      font-size: 1.5em;
      padding-bottom: 0.5em;
    }
    li {
      padding-bottom: 0.5em;
    }
    li a.active {
      color: #fff;
    }
    ul li.expanded {
      color: #fff;
    }
  }
}


.committee-submit-button {
  padding: 0.5em 3.4em;
  text-align: center;
  color: #fff;
  display: none;
  margin-top: 1em;
  text-transform: uppercase;
  cursor: pointer;
  background: $brand-primary;
  &.clicked {
    display: inline-block;
    background: $brand-fifth;
    &:hover {
      background: $brand-primary;
    }
  }
}


.view-720-voting-committee-member-dashboard {
  .throbber {
    display: none!important;
  }
  .ajax-process {
    display: none!important;
  }
  .views-field-nothing {
    position: relative;

  }
}



.vud-link-up,
.vud-link-down {
  //pointer-events: none;
}

.vud-widget-thumbs {
  margin: 0!important;
  float: none!important;
  .up-score, .down-score {
    padding: 0!important;
    border-right: 0!important;
    cursor: pointer;
    a {
      pointer-events: none;
      display: inline-block;
    }
    .chosen {
      .vote-thumb {
        &:after {
          background: $brand-third;
        }
      }
    }
    a:hover {
      text-decoration: none;
      > div {
        &:after {
          background-color: $brand-third;
        }
      }
    }
  }
}
.vote-thumb {
  &.up-active {

    &:after {
      background: $brand-third!important;
    }
  }
  &.down-active {
    &:after {
      background: $brand-third!important;
    }
  }
  .up-score & {
    float: none!important;
    background: none!important;
    width: auto!important;
    height: auto!important;
    font-weight: normal!important;

    &:before {
      content: "Approve";
      display: block;
      padding-bottom: 0.5em;
      margin-top: 0.5em;
      color: #5A5A5A;
    }
    &:after {
      line-height: 2em;
      font-size: 2em;
      background: #DADADA;
      font-family: FontAwesome;
      content: "\f00c";
      color: #fff;
      padding: 0.2em 0.3em;
      @include breakpoint(md){
        padding: 0.6em 0.8em;
      }
    }
  }
  .down-score & {
    float: none!important;
    background: none!important;
    width: auto!important;
    height: auto!important;
    font-weight: normal!important;
    margin-right: 0.1em!important;
    &:before {
      content: "Decline";
      display: block;
      padding-bottom: 0.5em;
      margin-top: 0.5em;
      color: #5A5A5A;
    }
    &:after {
      line-height: 2em;
      font-size: 2em;
      background: #DADADA;
      font-family: FontAwesome;
      content: "\f00d";
      color: #fff;
      padding: 0.2em 0.3em;
      @include breakpoint(md){
        padding: 0.6em 0.8em;
      }
    }
  }
}
.vote-current-score {
  display: none!important;
}

.page-header {
  border-bottom: 0!important;
  padding: 0!important;
}

.voted-how {
  margin-top: 1em!important;
  visibility: hidden;
}

.pager {
  margin-top: 2em!important;
  li {
    padding: 0!important;
  }
}

.no-padding {
  padding: 0;
}

.footer-right {
  @include breakpoint(md){
    text-align: right;
  }
}

.scorecard-container {
  display: none;
}

.scorecard-percent {
    max-width: 105px;
    padding: 10px;
    margin: 10px 0 10px 10px;
    text-align: center;
    background: #c9c9c9;
    background: -moz-linear-gradient(top, #ddd 0%, #c9c9c9 100%);
    background: -webkit-linear-gradient(top, #ddd 0%, #c9c9c9 100%);
    background: -o-linear-gradient(top, #ddd 0%, #c9c9c9 100%);
    background: -ms-linear-gradient(top, #ddd 0%, #c9c9c9 100%);
    background: linear-gradient(to bottom, #ddd 0%, #c9c9c9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ddd', endColorstr='#c9c9c9',GradientType=0 );
    border: 1px solid #bbb;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    div.scorecard-score span {
      font-size: 23px;
      font-weight: bold;
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
      padding: 0 0 0 3px;
      line-height: 28px;
    }
}

.exit {
  font-weight: bold;
  margin-right: 1.5em!important;
}
.heading {
  text-align: left;
}

.arrow,
.plus {
  font-size: 1.5em;
  line-height: 1em;
}
.view-720-voting-view-votes {
  .arrow,
  .plus {
    font-size: 0.8em;
    line-height: 1em;
  }
}

.main-content {
  background: #fff;
}

.table {
  @include breakpoint(md){
    display: table;
  }
}
.table-cell {
  @include breakpoint(md){
    display: table-cell;
  }
}

#ide .scorecard-percent div.scorecard-label {
  display: block!important;
}

.loader {
  color: #000;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.page-user {
  #page-header {
    display: none;
  }
}