.pane-footer-minipanel {
  text-align: center;

  // Footer copyright...
  .pane-custom > .pane-content > p {
    color: #FFF;
    margin-bottom: 0;
  }
}

#footer {
  a,p {
    color: #fff;
  }
  ul li {
    list-style: none;
  }
} 
