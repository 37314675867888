// Styles for Forms
// -----------------------------------------------------------------------------

.chosen-single {
  height: auto!important;
  border: 1px solid $brand-fourth!important;
  border-radius: 0!important;
  background: #fff!important;
  span {
    padding: 0.5em;
  }
  div {
    background: $brand-secondary!important;
    width: 26px!important;
    b {
      text-align: center;
      background: none!important;
      display: table!important;
      background-image: none!important;
      &:after {
        vertical-align: middle;
        // display: table-cell;
        padding-top: 0.4em;
        display: block;
        content: "\f0d7";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        color: #fff;
        .chosen-with-drop & {
          content: "\f0d8";
        }
      }
    }
  }
}
.form-item-field-720-voting-cand-name-value {
  position: relative;
  &:after {
    position: absolute;
    right: 1em;
    top: 0.6em;
    content: "\f002";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
  }
  input {
    max-width: 300px;
    padding-right: 1.5em;
    font-size: 1.6em;
  }
}
.form-item-field-720-voting-event-date-value-value-month {
  @include breakpoint(sm){
    margin-right: 1em!important;
  }
}

.node-720_voting_tool_events-form {
  label {
    color: $gray-light;
    letter-spacing: 1px;
  }
  input {
    border: 1px solid $brand-fourth!important;
  }
  .field-name-field-candidate-id,
  .field-name-field-720-voting-event-candidate,
  .field-name-field-committee-id,
  .field-name-field-720-voting-event-committee,
  .field-name-field-candidate-office,
  .field-name-field-candidate-district,
  .field-name-field-candidate-state,
  .field-name-field-candidate-party,
  .field-name-field-year {
    @include breakpoint(sm){
      display: inline-block;
      width: 50%;
      padding: 0 0.5em;
    }
    input {
      width: 100%;
    }
  }
  .field-name-field-return-amount {
    padding-left: 10px;
  }
  .field-name-field-return-amount,
  .field-name-field-720-voting-event-amount,
  .field-name-field-720-voting-event-date,
  .field-name-field-election-type,
  .field-name-field-lobbyist-attendee {
    display: inline-block;
    vertical-align: top;
    label {
      display: block;
    }
  }
  .field-name-field-720-voting-event-date,
  .field-name-field-election-type,
  .field-name-field-lobbyist-attendee {
    padding: 0 0.5em;
  }

 .form-item-title,
 .form-item-field-720-voting-event-candidate-und-0-target-id {
   label {
     display: none;
   }

   input {
     font-size: 1.5em;
     font-weight: bold;
     padding: 0.5em;
   }
 }
}
.form-type-date-popup {
  label {
    display: none;
  }
}
.field-type-datetime {
  legend {
    display: none;
  }
}
.date-padding {
  padding: 0!important;
}
.form-type-date-popup {
  .description {
    display: none!important;
  }
}
.form-item-field-720-voting-event-election-und-0-target-id,
.form-item-field-720-voting-event-amount-und-0-value,
.form-item-field-720-voting-event-lobbyist-und-0-target-id,
.form-item-field-720-voting-event-date-und-0-value-date {
  label, .field-prefix {
    display: none;
  }
}
.form-item-field-720-voting-event-date-und-0-value-date label {
  display: block!important;
}
.ui-datepicker {
  background: #fff!important;
  border: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
  box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
  .ui-widget-header {
    background: #fff;
    border: 0;
  }
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    background: #fff;
    border: 0;
  }
  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    background: $brand-secondary;
    color: #fff;
    border: 0;
  }
}

#user-login {
  label {
    display: none;
  }
  .form-submit {
    display: inline-block;
    background: $brand-fifth;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    padding: 0.7em 2em;
    margin-left: 0;
  }
}
#send-to-vote-table {
  .heading {
    text-align: left!important;
    margin-bottom: 10px!important;
    text-transform: capitalize!important;
    color: #000!important;
  }
  span:nth-child(1) {
    text-transform: uppercase;
    text-align: center;
  }
  span:nth-child(2) {
    text-transform: uppercase;
    text-align: center;
    color: #00A9E7;
    font-size: 1.3em;
  }
  tr td:nth-child(1) {
    background: #F8F8F8;
  }
}

#edit-submit {
  display: inline-block;
  background: $brand-fifth;
  color: #fff;
  text-transform: uppercase;
  border: 0!important;
  padding: 0.7em 2em;
}

#edit-delete {
  display: inline-block;
  background: $brand-fifth;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  padding: 0.7em 2em;
  margin-left: 1em;
  border: 0!important;
}

#seventwenty-voting-update-contributions-form {
  #edit-federal-or-state {
    display: inline-block;
    margin-top: 3em;
    margin-bottom: 3em;
    float: left;
    -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    @include breakpoint(md){
      // padding: 1em;
    }
    input[type=checkbox]:checked + label:after {
      content:"Test";
    }
    .form-item {
      display: inline-block;
      text-align: center;
      padding: 2em;
      min-width: 180px;
      margin: 0;
      &:first-child {
          border-right: 3px solid #e7e7e7;
      }

      label {
        display: block;
        font-weight: bold;
        font-size: 2em;
        padding-top: 0.2em;
        &:after {
        }
      }
    }
  }
  .form-item-election-type {
    clear: both;
    margin-right: 1em;
  }

  .form-item-election-type,
  .form-item-election-year {
    @include breakpoint(md){
      display: inline-block;
      float: left;
    }
  }
  #edit-submit {
    margin-top: 0!important;
    margin-left: 1em;
    @include breakpoint(md){
      float: left;
    }
  }
  h2 {
    clear: both;
    font-weight: bold;
    @include breakpoint(md){
      float: left;
    }
  }

}

#contribution-wizard-table {
  border-top: 0!important;
  tbody {
    border-top: 0!important;
  }
}
.form-submit {
  display: inline-block;
  background: $brand-fifth;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  padding: 0.7em 2em;
  margin-left: 1em;
  border: 0!important;
}

.line-bottom {
  border-bottom: 7px solid #00ACA7;
}

.form-type-radio {
  position: relative;
  .checked {

  }
}

#edit-field-720-voting-cand-name-value {
  font-size: 1.75em;
  max-width: 300px;
  background: #fff!important;
  background-image: none!important;
  border: 1px solid #aaa;
}

.form-item-field-720-voting-event-committee-und-0-target-id {
  label {
    display: block;
  }
}

.form-item-field-720-voting-event-committee-und-0-target-id {
  label {
    display: block;
  }
  input {
  }
}
.form-item-field-720-voting-event-amount-und-0-value {
  label {
    display: block;
  }
  input {

  }
}
.page-node-add-720-voting-tool-events {

}
#send-to-vote-table {
  .arrow {
    font-size: 0.8em!important;
  }
}

.ui-dialog .ui-dialog-titlebar-close {
    outline: none!important;
}

.field-group-accordion-wrapper {
  .ui-state-default {
    background: #eee;
  }
}

#past-contributions {
  padding: 15px;
  border: 1px solid #efefef;
  margin-bottom: 1em;
  h3 {
    margin-top: 0;
    color: #999;
    font-weight: bold;
  }
  .form-item {
    margin-bottom: 0;
    input {
      pointer-events:none;
    }
  }
  #edit-field-contribution-limit-primary {
    display: inline-block;
    padding-right: 1em;
  }
  #edit-field-contribution-limit-general {
    display: inline-block;
    padding-right: 1em;
  }
  #edit-field-contribution-limit-special {
    display: inline-block;
  }
}

#committee-results {
  padding-top: 1em;
  p {
    margin-bottom: 0;
  }
}

.form-select {
  box-shadow: none!important;
  border: 0!important;
  padding: 0!important;
}

.form-control {
  height: 42px!important;
}

.nav>li>a:hover {
  background: #A6192E;
}

#edit-field-lobbyist-attendee-und option[value='24006'] {
  display: none!important;
}
#edit-field-lobbyist-attendee-und option[value='24008'] {
  display: none!important;
}
#edit-field-lobbyist-attendee-und option[value='24011'] { 
  display: none!important;
}

