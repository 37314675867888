.pane-header-minipanel {
  background: $brand-white;
  border-bottom: 1px solid darken($brand-primary, 10%);

  ul.links {
    margin: 0;

    li {
      float: left;
    }
  }


  .pane-page-site-name {
    border-right: 1px solid #FFF;
  }

  .pane-page-primary-links {}

  .pane-search-form {
    float: right;
  }
  .pane-page-secondary-links {
    float: right;
    ul.links {
      li {
        float: right;
      }
    }
  }

  // Remove padding in the row... Not needed in the header.
  .col-md-12 {
    padding: 0 !important;
  }

  // Header responsive menus...
  .responsive-menus.responsified span.toggler {
    padding: 0;
    display: block;
    background: none;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    font-size: 1em;
    text-align: center;
    cursor: pointer;
    outline: none;
    margin-left: .5em;
  }

  .responsive-menus.responsified.responsive-toggled.absolute .responsive-menus-simple {
    top: auto;
    left: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
  }
  .responsive-menus.responsified.responsive-toggled.absolute {
    position: inherit;
  }

  .responsive-menus.responsified .responsive-menus-simple li a {
    display: block;
    width: 100%;
  }
}

@media (max-width: 550px) {

}

@media (max-width: $screen-sm) {
  .pane-page-site-name {
    display: none;
  }
}






.header-section {
  background: #fff;
  img {
    max-width: 300px;
    margin-top: 2em;
    margin-bottom: 2em;
    @include breakpoint(sm){
      margin-left: 3em;
    }
  }
}
