// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 3em;
  min-height: 100%;
}

body.navbar-administration {
  margin-top: 0 !important;
  padding-bottom: 3em;
}

#navbar-administration.navbar-oriented .navbar-tray-vertical {
  width: 240px;
}
body.navbar-tray-open.navbar-vertical.navbar-fixed {
  margin-left: 240px;
}

// corrects issue of vert menu overlaying body section.
body.navbar-tray-open.navbar-vertical #navbar-administration {
  left: -150px;
}

// Correct sticky header showing under some elements...
.sticky-header {
  z-index: 100;
}
