// Styles for Nodes
// -----------------------------------------------------------------------------

.node {}

.content {
  padding: 1em 0;
}

.page-administration-contribution-wizard {
  .region-content {
    #edit-submit {
      display: inline-block;
      background: #00A9E7;
      color: #fff;
      text-transform: uppercase;
      border: 0;
      margin-top: 1.6em !important;
      padding: 0.65em 2em;
    }
  }
}
.page-administration-send-to-vote {
  #send-to-vote-table {
    @include breakpoint(md){
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,0.5);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,0.5);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,0.5);
      padding: 1em;
    }
  }


  #edit-submit {
    display: inline-block;
    background: #00A9E7;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    margin-top: 1.6em !important;
    padding: 0.65em 2em;
  }
}

.page-node-36205 {
  .view-header {
    display: none;
  }
  .vud-widget {
    pointer-events: none;
  }
}

.page-calendar-view-pending-events-new {
  .messages {
    ul li:last-of-type {
      display: none!important;
    }
  }
}

.page-node-add-720-voting-tool-events {
  .field-name-field-return-amount {
    display: none;
  }
}
