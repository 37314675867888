// Styles for Views
// -----------------------------------------------------------------------------
#seventwenty-voting-admin-dashboard-form {
  @include breakpoint(md){
    margin-top: 3em;
  }
  #send-to-vote-table {
    @include breakpoint(md){
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    }
  }
  > div {
    text-align: center;
    th {
      color: $brand-fourth;
    }
    tr {
      td {
        &:nth-child(1) {
          background: #F8F8F8;
          span {
            &:nth-child(1) {
              text-transform: uppercase;
              display: block;
              text-align: center;
            }
            &:nth-child(2) {
              display: block;
              text-align: center;
              color: $brand-secondary;
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
  .form-submit {
    display: inline-block;
    margin: 3em 1em 1em 1em;
    background: $brand-fifth;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    padding: 0.7em 2em;
  }
}
.view-720-voting-committee-member-dashboard {
  .view-header {
    font-weight: bold;
    font-size: 1.1em;
    color: #B5B5B5;
    letter-spacing: 1px;
    margin-bottom: 1.5em;
  }
  .view-content {

  }
  .views-table {
    border: 0!important;
    tbody {
      border-top: 0;
    }
    tr {
      background: transparent;
      td {
        display: block;
        @include breakpoint(sm){
          display: table-cell;
        }
        @include breakpoint(md){
          padding: 1em 0;
          &:first-child {
            width: 60%;
          }
          &:last-child {
            width: 35%;
          }
        }
        @include breakpoint(lg){
          &:first-child {
            width: 70%;
          }
          &:last-child {
            text-align: right;
            width: 28%;
          }
        }
      }
    }
  }
  .views-field-nothing > a {
    display: inline-block;
    background: $brand-third;
    padding: 0.5em 2.2em;
    color: #fff;
    text-transform: uppercase;
    &:hover, &:focus {
      text-decoration: none;
      background: $brand-primary;
    }
  }
  .views-field-title {
    > a {
      display: none;
    }
    br {
      display: none;
    }
  }
  .comments {
    padding-top: 1em;
  }
  .candiate-name {
    .text-wrapper {
      display: inline-block;
      vertical-align: top;
      line-height: 1.1em;
    }
    i {
      font-size: 2em;
      display: inline-block;
      margin-right: 0.5em;
      vertical-align: top;
      color: #b5b5b5;
    }
    .text,
    .date {
      display: block;
    }
  }
}

.view-720-voting-search-candidates,
.view-720-voting-search-candidates-2 {
  margin-top: 1em;
  > .view-content {
    @include breakpoint(md){
      margin-top: 3em;
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    }
    tr {
      background: transparent;
      &.odd {
        // background: #efedee;

      }
      .views-field-nothing,
      .views-field-nothing-1 {
        background: #efedee;
        a {
          &:first-of-type {
            background: $brand-third;
            color: #fff;
            display: block;
            text-align: center;
            padding: 1em;
            text-transform: uppercase;
            margin-bottom: 1em;
            letter-spacing: 1px;
            @include breakpoint(md){
              margin-right: 1em;
            }
          }
          &.read-more-link {
            display: block;
            text-align: center;
            color: $brand-secondary;
            font-size: 1.3em;
            @include breakpoint(md){
              margin-right: 1em;
            }
          }
        }
      }
      .view-within-search-event-comments {
        padding: 0 1em;
      }
    }
  }
  .views-widget-filter-name {
    label {
      display: none;
    }
  }
}
.view-720-voting-view-votes {
  > .view-content {
    @include breakpoint(md){
      margin-top: 3em;
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,0.5);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,0.5);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,0.5);
    }
    tr {
      background: transparent;
    }
  }
  .views-field-views-conditional {
    background: #efedee;
    padding: 0!important;
  }
}
.views-submit-button {
  input {
    display: inline-block;
    background: $brand-secondary;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    margin-top: 1.6em!important;
    padding: 0.65em 2em;
    &:hover {
      background: $brand-third;
    }
  }
}
.view-720-voting-tool-view-pending {
  .view-content {
    @include breakpoint(md){
      margin-top: 3em;
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    }
    tr {
      background: transparent;
      td {
        &:nth-child(1) {
          background: #F8F8F8;
          span {
            &:nth-child(1) {
              text-transform: uppercase;
              display: block;
              text-align: center;
            }
            &:nth-child(2) {
              display: block;
              text-align: center;
              color: $brand-secondary;
            }
          }
        }
      }
    }
  }
}

.views-field-field-720-voting-event-date {
  div {
    color: $brand-secondary;
    font-size: 1.2em;
  }
}
.view-view-calendar {
  .view-content {
    @include breakpoint(md){
      margin-top: 3em;
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    }
    tr {
      background: transparent;
      td {
        &:nth-child(1) {
          background: #F8F8F8;
          span {
            &:nth-child(1) {
              text-transform: uppercase;
              display: block;
              text-align: center;
            }
            &:nth-child(2) {
              display: block;
              text-align: center;
              color: $brand-secondary;
            }
          }
        }
      }
    }
  }
}

.view-id-people {
  .view-content {
    @include breakpoint(md){
      margin-top: 3em;
      -webkit-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      -moz-box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
      box-shadow: 0px 0px 20px 5px rgba(201,201,201,1);
    }
    tr {
      background: transparent;
    }

  }
  .view-header {
    a {
      background: $brand-primary;
      color: #fff;
      padding: 1em 2em;
      position: absolute;
      top: 1.8em;
      right: 0;
      @include breakpoint(md){
        top: 3.4em;
        right: 2em;
      }
      &:hover, &:focus {
        background: $brand-secondary;
      }
      &:before {
        content: "\f007";
        font-family: FontAwesome;
        margin-right: 0.4em;
        font-weight: lighter;
      }
    }
  }
}

.secondary-row {
  > td {
    padding: 1em 0;
  }
  .heading {
    font-weight: lighter!important;
    padding-left: 7%!important;
  }
  .event-comments {
    padding-left: 7%!important;
  }
}

.views-field-field-720-voting-cand-name {
  font-weight: bold;
}
.views-field-field-720-voting-event-candidate {
  font-weight: bold;
}
.views-field-field-720-voting-event-comments {
  background: #efedee;
  padding: 0!important;
  .plus {
    background: #D3D3D3;
    padding: 0.85em 0.8em;
    font-size: 12px;
  }
  span {
    display: inline-block;
    vertical-align: top;
    padding-top: 0.5em;
  }
  .comments {
    padding: 1em;
  }
}
.view-720-voting-vote-results-on-event {
  background: #e5e5e5;
  padding: 0.05em 1em;
  @media (min-width: 1300px) {
    min-width: 510px;
  }
  th {
    border: 0!important;
    padding: 0.4em 0;
  }
}

.scorecard {
  h1 {
    display: none;
  }
}
.page-people {
  #content {
    position: relative;
  }
}
.page-candidates-search-candidates #ui-id-1 {

    &:before {
      content: "Contribution History";

    }

}
.ui-dialog .view-id-contribution_history {
  .view-content {

  }
}

.view-720-voting-committee-member-dashboard {
  .committee-id-value {
    display: none;
  }
  .contrib-history {
    display: inline-block;
    h3 {
      margin: 0;
      font-size: 1.2em;
    }
    > div {
      padding-top: 0.6em;
      padding-bottom: 0.6em;
      display: inline-block;
      span {
        padding-right: 5px;
      }
    }
    .can-id-value {
      display: none;
    }
  }
}