// Search styles
.pane-search-form {
  position: relative;

  .form-control {
    height: auto;
    padding: 2px 10px;
    margin-top: .65em;
    padding-right: 20px;
  }
  .form-item-search-block-form {
    float: left;
  }

  .form-submit {
    color: #fff;
    background: none;
    border: none;
    padding: 2px 10px;
    text-indent: -999em;
    position: absolute;
    top: .65em;
    right: -10px;
    z-index: 3;
  }

  i.fa-search {
    position: absolute;
    top: 1em;
    right: 5px;
    z-index: 2;
    color: $brand-primary;
  }
}

@media (max-width: $screen-sm) {
  .pane-search-form {
    .form-control {
      width: 100px;
    }
  }
}
