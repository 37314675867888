// Typography
// -----------------------------------------------------------------------------
@font-face {
    font-family: "ExxonMobile";
    src: url("../fonts/EMprint-Regular.woff");
}
@font-face {
    font-family: "ExxonMobile";
    src: url("../fonts/EMprint-Bold.woff");
    font-weight: bold;
}
@font-face {
    font-family: "ExxonMobile";
    src: url("../fonts/EMprint-Light.woff");
    font-weight: 300;
}

body {
  font-family: $font-family-sans-serif;
}
