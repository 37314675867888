// Styles for panels and panes.
// -----------------------------------------------------------------------------

body > .panel-display > .panel-panel > div > .pane-page-content {
  margin-bottom: 1em;
}

.pane-seven-twenty-site-logo {
  img {
    width: 200px;
    height: 100px;
  }
}

.pane-page-content {
  background: #fff;
}
