// Styles for blocks.
// -----------------------------------------------------------------------------
#content {
  padding: 1em;
  @include breakpoint(md){
    padding: 3em 2em;
  }
  @include breakpoint(lg){
    max-width: 1000px;
  }
}
.chosen-rtl .chosen-search input[type="text"], .chosen-container-single .chosen-single abbr, .chosen-container-single .chosen-single div b, .chosen-container-single .chosen-search input[type="text"], .chosen-container-multi .chosen-choices .search-choice .search-choice-close, .chosen-container .chosen-results-scroll-down span, .chosen-container .chosen-results-scroll-up span {
  background-image: none!important;
}
.block-menu {
  .block__title {
    color: #fff;
    font-size: 1.7em;
    padding-left: 5%;
    @include breakpoint(md){
      padding-left: 15%;
    }
  }
}
#results {
  > table {
    margin-top: 2em;
    box-shadow: 0px 0px 20px 5px #eee;
  }
  .no-results {
    margin-top: 2em;
  }
}
.comments-table {
  background: #efedee;
}
.node-type-720-voting-tool-events,
.page-node-add-720-voting-tool-events {
  #results {
    margin-bottom: 2em;
  }

  #search {
    margin-bottom: 1em;
  }
}

.node-type-720-voting-tool-events {
  .field-name-field-720-voting-approve-or-dec {
    display: none;
  }
}

.search-candidates {
  label {
    display: none;
  }
  .form-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 0.7em;
  }
  #name {
    max-width: 320px;
    padding-right: 1.5em;
    padding: 0.6em;
  }
  #district {
    max-width: 85px;
    padding: 0.6em;
  }
  .form-submit {
    margin-left: 0;
  }
  .search-wrapper {
    margin-right: 1em;
    display: inline-block;
    position: relative;
    vertical-align: top;
    &:after {
      position: absolute;
      right: 1em;
      top: 0.6em;
      content: "\f002";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
    }
    .form-item {
      margin-right: 0;
    }
  }
}