// Styles for Menus and Navs
// -----------------------------------------------------------------------------
#sidr-0-button {
  position: fixed;
  top: 2em;
  right: 0;
  color: #000;
}

 #sidebar {
   .menu {
     padding: 0;
     margin-left: 0;
     > li {
       list-style-type: none;
       list-style-image: none;
       font-weight: lighter;
       padding: 0;
       margin: 0;
       span,a {
         text-align: center;
         padding-top: 0.5em;
         padding-bottom: 0.5em;
         display: block;
       @include breakpoint(md){
           text-align: left;
           padding-left: 25%;
         }
         &:before {
           font-weight: normal;
           font-style: normal;
           display: inline-block;
           text-decoration: inherit;
           font-family: FontAwesome;
           margin-right: 0.5em;
         font-size: 1.3em;
         }
       }
       &.expanded {
         .caret {
           display: none;
         }
         .menu {
           padding-top: 0.5em;
           padding-bottom: 0.5em;
           background: #A6192E;
           @include breakpoint(md){
             padding-left: 10%;
           }
         }
       }
     }
   }
   .icon-candiates {
     span {
        &:before {
        content: "\f2be";
      }
    }
  }
  .icon-calendar {
    > a {
      &:before {
        content: "\f133";
      }
    }
    .menu {
      a {
        &:before {
          display: none;
        }
      }
    }
  }
  .icon-eye {
    a {
      &:before {
        content: "\f06e";
      }
    }
  }
  .icon-cog {
    span {
      &:before {
        content: "\f013";
      }
    }
  }
   .icon-signout {
     a {
       &:before {
         content: "\f08b";
       }
     }
   }
   .icon-profile {
     a {
       &:before {
         content: "\f007";
       }
     }
   }
 }
